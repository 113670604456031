import React, {useState} from "react";
import Scramble from "react-scramble";
import "./Membre.css"
import VizSensor from 'react-visibility-sensor';



export default function Membre({nom,role,step,descr,img}) {
  
  const [imgViz,setViz] = useState(false);

    return (
    <div className="membre" >
      <div className="membre_container">
      <img
        className="membre_photo"
        src={img}
      />
      </div>
      
      {imgViz && 
      <Scramble
        className="membre_scramble"
        autoStart
        text={nom}
        speed="medium"
        steps={[
          {
            roll: step,
            action: "+",
            type: "all",
          },
          {
            action: "-",
            type: "forward",
          },
        ]}
      />}
      
      <VizSensor
        onChange={(isVisible) => 
          setViz(isVisible || imgViz)
        }
      >
      <p className ="membre_role"> {role}</p>

      </VizSensor>
      <p className ="membre_role"> {descr}</p>


    </div>
  );
}
