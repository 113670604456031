import React from "react";
import "./Activites.css";
import "./Equipe.css";
import {programme} from "./prog.js"
import {programmeEn} from "./prog.js"

/*function update(e) {
    var x = e.clientX || 0;
    var y = e.clientY || 0;
    
    let elem = document.querySelector(".newRoot");

    if(elem){
    elem.style.setProperty("--cursorX", x + "px");
    elem.style.setProperty("--cursorY", y + "px");}
  }
  
  document.addEventListener("mousemove", update);
  document.addEventListener("touchmove", update);*/

export default function Activites(props) {
  var lang = props.match.params.lang;
  const prog = lang == "fr" ? programme : programmeEn;

  return (
    <div className="equipe">
      <h1> {lang == "fr" ? "Notre programme :" : "Our program"} </h1>

      <div
        style={{
          width: "95%",
          maxWidth: 900,
          padding: 20,
          marginBottom:30,
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "rgb(0,0,0,0.5)",
        }}
      >
        {prog.map((e) =>  (<div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",}}> <h2 className="sousTitre">{e[0]}</h2>
        <p className="sousProgramme">
         {e[1]}
        </p> </div>))}
        
      </div>
    </div>
  );
}
