import React, { useEffect, useState } from "react";
import "./Accueil.css";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { membres } from "./membres";
import Membre from "./Membre";
import Minesweeper from "react-minesweeper";
import "react-minesweeper/lib/minesweeper.css";

export default function Accueil(props) {
  const size = useWindowSize();
  const [win, setWin] = useState(0);

  var lang = props.match.params.lang;
  const widthInner = size.height * 0.65 * (16 / 9);
  const heightInner = (size.width * 0.9) / (16 / 9);

  return (
    <div className="acc">
      <div className="acc_part1">
        <img
          className="acc_logo"
          src="https://media.discordapp.net/attachments/769160709837684737/775132454889455626/Fichier_1.png?width=741&height=677"
        />
        <div className="acc_part1Text">
          <h1>
            {" "}
            {lang == "fr" ? "Liens utiles :" : "Useful links :"}
          </h1>
          <div className="acc_choices">
            <Link className="choice" to={"/equipe/" + lang}>
              {lang == "fr" ? "Nos agents" : "Our team"}
            </Link>
            <a className="choice" href="http://enquete.classified.iiens.net/" target="_blank">
              {" "}
              {lang == "fr" ? "L'enquete" : "The investigation"}
            </a>
          </div>
        </div>
      </div>
      <Link className="secret" to={"/cookie/fr"}>
        <div className="sep"></div>
      </Link>
      <div className="acc_part3">
        <h1 className="acc_pres">
          {" "}
          {lang == "fr"
            ? "Notre vidéo de présentation :"
            : "Our presentation video"}
        </h1>

        <ReactPlayer
          width={1300 > size.width ? size.width * 0.9 : widthInner}
          height={1300 > size.width ? heightInner : size.height * 0.65}
          controls={true}
          url="https://youtu.be/XoBAjXBHQNY"
        />
      </div>

      <div style={{ width: "100%" }}>
        <Link className="secret" to={"/cookie/fr"}>
          <div className="sep sep2" style={{}}></div>
        </Link>
        <div className="acc_partBomb">
          <h1 className="mineSweep">
            {lang == "fr"
              ? win == 0
                ? "Aidez nos agents à trouver les bombes !"
                : win == 1
                ? "Merci pour vos talents inégalés ! Vous nous avez tous sauvés"
                : "Nos agents sont tous morts... bravo"
              : win == 0
              ? "Help our agents to detect the mines"
              : win == 1
              ? "You are incredible ! Thanks"
              : "Our agents are all dead because of you..."}
          </h1>
          {size.width > 900 ? (
            <Minesweeper
              className="mineS"
              onWin={() => setWin(1)}
              onLose={() => setWin(2)}
              bombChance={0.15} // 15% chance that a field will contain a bomb
              width={15} // amount of fields horizontally
              height={15} // amount of fields vertically
            />
          ) : (
            <div>
              <Minesweeper
                className="mineS"
                onWin={() => setWin(1)}
                onLose={() => setWin(2)}
                bombChance={0.15} // 15% chance that a field will contain a bomb
                width={8} // amount of fields horizontally
                height={8} // amount of fields vertically
              />
            </div>
          )}
        </div>
      </div>

      <div className="acc_part4">
        <div className="footer">
          {" "}
          <a href="https://discord.gg/x24Msvwtmh">Discord </a>
          <a href="https://www.facebook.com/classified.ensiie">Facebook</a>
          <a href="https://www.twitch.tv/classified_liste_bde">Twitch</a>{" "}
          <a href="https://www.instagram.com/classified.ensiie/?hl=fr">
            Instagram
          </a>{" "}
          <a href="https://dino.eliottgandiolle.fr">AgentGame </a>
          <a href="https://2048.eliottgandiolle.fr">2048</a>
        </div>
      </div>
    </div>
  );
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    var body = document.body,
      html = document.documentElement;
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        pageHeight: Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        ),
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
