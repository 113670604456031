export let programme = [
  [
    `Communication :`,
    `L’utilisation de Discord est devenue très importante ces derniers
          temps. Cependant, nous ne souhaitons pas que les informations se
          perdent entre les mails et Discord. C’est pourquoi nous voulons
          relayer de manière égale et équilibrée les informations sur Discord et
          sur les mailing-lists afin que personne ne soit laissé de côté. De
          plus, nous continuerons à être présents sur les réseaux sociaux.`,
  ],
  [
    ` Voyages :`,
    `
          Nous organiserons les voyages entamés par IgnItE : le Ski et
          Amsterdam. Cependant, nous souhaitons organiser de nombreuses sorties
          accessibles à tous, plus courtes et axées localement. Nous proposerons
          de nombreux voyages à la journée ou sur le week-end, à plus petite
          échelle dans des destinations variées : des parcs d’attraction ou
          encore du camping sauvage.
        `,
  ],
  [
    `
          WEI : (d'après le docteur on aura un vaccin)
        `,
    `
          Il nous est actuellement presque impossible de se projeter sur le WEI.
          Mais si toutefois nous pouvons l’organiser, nous tenons à organiser un
          week-end d’intégration dans la continuité des BdE précédents, en
          privilégiant évidemment la bonne entente entre tous les élèves, toutes
          promotions confondues.
        `,
  ],
  [
    ` Lieux de vie à l’école :`,
    `
          Nous souhaitons garder un foyer le plus propre possible, en
          sensibilisant les élèves et en mettant à disposition des outils de
          nettoyage. Il va sans dire que nous continuerons d’accentuer le tri,
          déjà efficace et que nous renouvellerons une partie du matériel. Nous
          essaierons également de proposer des jeux collectifs (y compris vidéos)
          dans le foyer.
        `,
  ],
  [
    ` Intégration : `,
    `
          L’intégration des nouveaux élèves est primordiale. Afin d’intégrer au
          maximum les élèves étrangers, nous travaillerons à proposer un maximum
          de choses en anglais, comme la traduction de tous les mails partagés.
          Nous proposerons également des parrainages entre élèves français et
          étrangers sur la base du volontariat, visant à faire découvrir la
          culture française aux nouveaux arrivants en partageant habitudes, bons
          plans et connaissances de la région parisienne.
        `,
  ],
  [
    ` Point covid : `,
    `
          Nous devons cette année nous adapter à la situation sanitaire. Bien
          évidemment, toutes nos activités seront organisées en accord avec les
          consignes sanitaires pour que chacun puisse profiter du dynamisme de
          l’école en toute sécurité et en évitant tout risque malvenu. Nous
          souhaitons que les étudiants de l’école puissent profiter des nombreuses
          animations même si celles-ci devaient se tenir en distanciel.
        `,
  ],
  [
    ` Associations : `,
    `
          Les associations pourront effectuer des demandes auprès du BdE
          lorsqu'elles souhaitent organiser des activités, des évènements ou des
          sorties ouverts à tous les IIEns. Le BdE pourra alors décider
          d'assigner des membres afin de venir en aide à l'association si elle
          le souhaite. Cela a pour but d'encourager les associations à se lancer
          dans l'organisation d'activités, mais aussi d'accompagner et faire
          vivre les petites associations qui parfois ont besoin d'aide ou de
          conseils.
        `,
  ],
  [
    `DD&RS :`,
    `
          Nous souhaitons que le responsable DD&RS du BdE soit un vrai lien
          entre l’administration et les étudiants au sujet des problématiques
          environnementales et de la responsabilité sociétale. Pour cela nous
          souhaitons réaliser plus de communication sur les aides financières
          disponibles pour les élèves dans le besoin. Nous relayerons également
          d'avantage' les informations liées au développement durable au
          sein de l’ENSIIE.
        `,
  ],
  [
    `
        
          Améliorer la complicité entre BdE et partenariats:
        `,
    `
          Nous voulons mettre en place une newsletter destinée aux partenariats
          une fois tous les 2 mois afin de faire de la communication auprès
          d'eux. Cela permettra d'instaurer un lien de complicité avec les
          partenariats que nous avons afin de les inciter à maintenir et
          renouveler leurs accords avec nous, mais aussi pour espérer obtenir
          des avantages plus importants. Dans la même idée, nous aimerions créer
          une newsletter mensuelle des partenariats qui permet d'accéder au
          document à jour relatant tous les partenariats, qui décrit les
          nouveaux partenariats trouvés et qui parle des bon plans temporaires
          qu'on aura pu négocier avec les entreprises.
        `,
  ],
  [
    ` Vie des iiens :`,
    `
          Nous souhaitons mettre en place une plateforme grâce à laquelle les
          IIEns qui ont un logement pourront proposer des places pour héberger
          occasionnellement d'autres IIEns notamment lors des soirées. Cela
          permettra à ceux qui habitent loin de l'école d'avoir l'assurance
          d'être logés quelque part.
        `,
  ],
];


export let programmeEn = [
    [
      `Communication :`,
      `The use of Discord has become very important in the latter
      time. However, we do not want the information to be
      lose between mails and Discord. This is why we want to
      relaying information about Discord in an equal and balanced way and
      on mailing lists so that no one is left out. From
      more, we will continue to be present on social networks.`,
    ],
    [
      ` Travel :`,
      `
      We will organize the trips started by IgnItE: Skiing and
      Amsterdam. However, we would like to organize many outings
      accessible to all, shorter and locally focused. We will offer
      many day or weekend trips, to smaller
      scale in various destinations: amusement parks and other attractions or
      still wild camping.
          `,
    ],
    [
      `
            WEI : (according to Le Docteur, we will have a vaccine).
          `,
      `
      It is currently almost impossible for us to project ourselves onto the WEI.
      But if however we can organize it, we would like to organize a
      integration weekend in the continuity of the previous BoEs, in
      obviously favouring the good understanding between all students, all of them
      promotions combined.
          `,
    ],
    [
      ` Places of life at school :`,
      `
      We want to keep our school foyer as clean as possible, by
      sensitizing the students and by making available tools for
      cleaning. It goes without saying that we will continue to emphasize sorting,
      already effective and that we will renew some of the material. We
      will also try to offer collective games (including video)
      in the home.
          `,
    ],
    [
      ` Integration : `,
      `
      The integration of new students is essential. In order to integrate into the
      foreign students, we will work to offer a maximum
      things in English, such as the translation of all shared emails.
      We will also offer sponsorships between French and English students.
      foreigners on a voluntary basis, aimed at introducing the
      French culture to newcomers by sharing habits, good habits, good habits
      maps and knowledge of the Paris region.
          `,
    ],
    [
      ` Point covid : `,
      `
      This year we have to adapt to the health situation. Good
      obviously, all our activities will be organized in agreement with the
      health instructions so that everyone can take advantage of the dynamism of
      the school safely and avoiding any unwelcome risks. We
      we hope that the students of the school will be able to take advantage of many
      animations even if they had to be kept at a distance.
          `,
    ],
    [
      ` Associations : `,
      `
      Associations will be able to make requests to the BoE
      when they wish to organize activities, events or
      Outings open to all IIEns. The BoE will then be able to decide
      to assign members to assist the association if it
      wishes to do so. This is to encourage associations to launch
      in the organization of activities, but also to accompany and do
      live the small associations that sometimes need help or support.
      advice.
          `,
    ],
    [
      `DD&RS :`,
      `
      We want the DD&RS manager of the BdE to be a real link
      between the administration and the students about the issues
      environmental and social responsibility. To this end, we
      wish to carry out more communication on financial aid
      available for students in need. We will also try to
      to relay information related to sustainable development to the
      within the ENSIIE.
          `,
    ],
    [
      `
            
      Improve the complicity between BdE and partnerships:
      `,
      `
      We want to set up a newsletter for partnerships
      once every 2 months to communicate with the
      of them. This will help to establish a bond of complicity with the
      partnerships that we have in order to encourage them to maintain and
      to renew their agreements with us, but also to hope to obtain
      greater benefits. In the same vein, we would like to create
      a monthly partnership newsletter that provides access to the
      an updated document of all partnerships, which describes the
      new partnerships found and talking about temporary good plans
      that we will have been able to negotiate with the companies.
          `,
    ],
    [
      ` Life of iiens :`,
      `
      We wish to set up a platform through which the
      IIEns who have accommodation will be able to propose places to host
      occasionally other IIEns, especially during the evenings. That
      will allow those who live far from school to have the assurance that the
      to be housed somewhere.
          `,
    ],
  ];
  