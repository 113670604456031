import Pipo from "../assets/PPipo.jpg";
import Clemos from "../assets/TClemos.jpg";
import Lae from "../assets/PLae.jpg";
import Kutzu from "../assets/PKutzu.jpg";
import Moop from "../assets/PMoop.jpg";
import Ninja from "../assets/PNinja.jpg";
import Maka from "../assets/PMaka.jpg";
import Poulette from "../assets/PPoulette.jpg";
import Red from "../assets/PRed.jpg";
import Zou from "../assets/PZou.jpg";
import Ness from "../assets/PInes.jpg";
import Bouteille from "../assets/TBouteille.jpg";
import Choco from "../assets/TChoco.jpg";
import Fanta from "../assets/TFanta.jpg";
import Myr from "../assets/TMyr.jpg";
import Muf from "../assets/TMuf.jpg";
import Neron from "../assets/TNeron.jpg";
import Ramsay from "../assets/TRamsay.jpg";
import Sauvage from "../assets/TSauvage.jpg";
import Soyeux from "../assets/TSoyeux.jpg";
import Vendri from "../assets/TVendri.jpg";
import Docteur from "../assets/TDocteur.jpg";
import Clavi from "../assets/TClavi.jpg";
import Mayo from "../assets/TMayo.jpg";
import Yann from "../assets/TYann.jpg";
import Tito from "../assets/TTito.jpg";


let findImg = (nom) => {
  let tempo = nom.substring(nom.indexOf('"')+1,nom.length);
  const pseudo = tempo.substring(0,tempo.indexOf('"'));
  switch(pseudo) {
    case "Red" : return Red;
    case "Zou" : return Zou;
    case "Pipo" : return Pipo;
    case "Kutzu" : return Kutzu;
    case "Laé" : return Lae;
    case "Ninja": return Ninja;
    case "Maka" : return Maka;
    case "Clemos" : return Clemos;
    case "Moop" : return Moop;
    case "Poulette" : return Poulette;
    case "Ness" : return Ness;
    case "Choco" : return Choco;
    case "Fanta" : return Fanta;
    case "Bouteille" : return Bouteille;
    case "Myr" : return Myr;
    case "Mufasa" : return Muf;
    case "Néron" : return Neron;
    case "Ramsay" : return Ramsay;
    case "Sauvage" : return Sauvage;
    case "Soyeux" : return Soyeux;
    case "Vendri" : return Vendri;
    case "Le Docteur" : return Docteur;
    case "Clavi" : return Clavi;
    case "Mayo" : return Mayo;
    case "Leyann" : return Yann;
    case "Tito" : return Tito;

  }
}


let tab = [
    { nom: 'Constant "Pipo" Gayet', role: "Président",desc:"Passion flutiste"},
    { nom: 'Louise "Clavi" Hubert', role: "Vice présidente",desc:"Déesse du dance-floor" },
    { nom: 'Laëtitia "Laé" Bahous', role: "Sec Gen",desc:"Campagnarde par excellence" },
    { nom: 'Lilian "Moop" Leclerc', role: "Trésorier",desc:"Maître Scrabble" },
    { nom: 'Christophe "Zou" Zhou', role: "Vice Trésorier",desc:"Alerte rouge !" },
    { nom: 'Alexia "Poulette" Harivel', role: "Respo Comm",desc:"Reine des enfers" },
    { nom: 'Julie "Kutzu" Pronier', role: "Pôle Comm / Part",desc:"Tornade basque" },
    { nom: 'Vivien "Mayo" Gagliano', role: "Pôle Comm" ,desc:"Attention à la mouuuuusse"},
    { nom: 'Antoine "Fanta" La Fontaine', role: "Respo Anim",desc:"Regard pétillant" },
    { nom: 'Lilian "Mufasa" Mallardeau', role: "Pôle Anim / Respo Foyer",desc:"Lion d'Or" },
    { nom: 'Antoine "Maka" Sabatier', role: "Pôle Anim",desc:"Agent chapeauté" },
    { nom: 'Lucas "Choco" Oustric', role: "Pôle Anim" ,desc:"Amateur de patisseries chocolatées"},
    { nom: 'Thibaud "Sauvage" Davies', role: "Respo Part",desc:"Joyeusement déphasé" },
    { nom: 'Malik "Soyeux" Sari', role: "Pôle Part" ,desc:"Sécheur professionnel"},
    { nom: 'Clémence "Clemos" Clavel', role: "Respo Logistique" ,desc:"Soleil de vos coeurs"},
    { nom: 'Eliott "Red" Gandiolle', role: "DSI",desc:"By SFR" },
    { nom: 'Alexis "Tito" Keller', role: "Respo FIPA",desc:"Charmeur des cabarets" },
    { nom: 'Benoit "Vendri" Gendre', role: "Respo RI" ,desc:"Gendre de qualité"},
    { nom: 'Yann "Leyann" Renard', role: "Respo Voyages" ,desc:"37 friendzones cette saison"},
    { nom: 'Ines "Ness" Rhouati', role: "Respo Associations",desc:"Entre Pokahantas et Mulan" },
    { nom: 'Florian "Ramsay" Batard', role: "MH",desc:"Batard" },
    { nom: 'Maxime "Néron" Brouat', role: "MH",desc:"Vagabond weeb" },
    { nom: 'Dorian "Bouteille" Doan', role: "MH" ,desc:"Toujours en décalage horaire"},
    { nom: 'Quentin "Le Docteur" Delavelle', role: "MH" ,desc:"Druide de vos soirées"},
    { nom: 'Thomas "Ninja" Guitton', role: "MH",desc:"Homme Parfait" },
    { nom: 'Loïc "Myr" Themyr', role: "MH",desc:"Expert en liste" },
  ];

export const membres = tab.map((e) => e = {nom:e.nom,role:e.role,desc:e.desc,img:findImg(e.nom)});;