import React from "react";
import Membre from "./Membre";
import "./Equipe.css";
import {membres} from "./membres";


export default function Equipe(props) {

  var lang = props.match.params.lang

  return (
    <div className="equipe">
      <h1> {lang == "fr" ? "Composition de notre unité d'élite" : "Composition of our elite unit"} </h1>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {membres.map((e, i) => (
          <Membre
            key={e.nom}
            nom={e.nom}
            role={"Agent #" + (i + 1) + " : " + e.role}
            step={15}
            descr={e.desc}
            img={e.img}
          />
        ))}
      </div>
    </div>
  );
}
