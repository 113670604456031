import logo from "./logo.svg";
import "./App.css";
import NavBar from "./components/NavBar";
import Equipe from "./components/Equipe";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Accueil from "./components/Accueil";
import Programme from "./components/Programme";
import ParticlesBg from "particles-bg";
import Partenariats from "./components/Partenariats";
import Activites from "./components/Activites";
import Email from "./components/Email";
import Cookie from "./components/Cookie";
import ScrollToTop from "./ScrollToTop";

const globalState = {
  lang: true,
};

const globalStateContext = React.createContext(globalState);

function App() {
  const size = useWindowSize();
  const [started, setStart] = useState(false);

  let config = {
    num: [4, 7],
    rps: 0.1,
    radius: [5, 40],
    life: [1.5, 3],
    v: [2, 3],
    tha: [-40, 40],
    alpha: [0.6, 0],
    scale: [0.1, 0.4],
    position: "all",
    color: ["#2a2a29"],
    cross: "dead",
    // emitter: "follow",
    random: 15,
  };

  if (Math.random() > 0.85) {
    config = Object.assign(config, {
      onParticleUpdate: (ctx, particle) => {
        ctx.beginPath();
        ctx.rect(
          particle.p.x,
          particle.p.y,
          particle.radius * 2,
          particle.radius * 2
        );
        ctx.fillStyle = particle.color;
        ctx.fill();
        ctx.closePath();
      },
    });
  }

  let changingL = (checked) => {
    if (checked) {
      globalState.lang = checked;
    }
  };

  useEffect(() => {}, []);

  return (
    <globalStateContext.Provider value={globalState}>
      <div className="App">
        <Router>
          <ScrollToTop>
            <NavBar changeLang={changingL} />
            <div className="bg" style={{ height: size.pageHeight }}>
              <ParticlesBg
                color="black"
                type="custom"
                config={config}
                bg={true}
              />
            </div>
            <Switch>
              <Route path="/equipe/:lang" component={Equipe} />
              <Route path="/programme/:lang" component={Activites} />
              <Route path="/partenariats/:lang" component={Partenariats} />
              <Route path="/activites/:lang" component={Programme} />
              <Route path="/cookie/:lang" component={Cookie} />
              <Route path="/2048" render={() => (window.location = "https://2048.eliottgandiolle.fr")} />
              <Route path="/dino" render={() => (window.location = "https://dino.eliottgandiolle.fr")} />

              <Route path="/:lang" component={Accueil} />
              

              <Route path="/">
                <Redirect to="/fr" />
              </Route>
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    </globalStateContext.Provider>
  );
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    var body = document.body,
      html = document.documentElement;
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        pageHeight: Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        ),
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default App;
