import React, { useState, useEffect } from "react";
import "./Cookie.css";
import Pipo from "../assets/PIPO.png";
import Moop from "../assets/MOOP.png";
import Fanta from "../assets/FANTA.png";
import Maka from "../assets/MAKA.png";
import Poulette from "../assets/POULETTE.png";
import Thibaud from "../assets/THIBAUD.png";
import Clavi from "../assets/CLAVI.png";
import Ness from "../assets/INES.png";
import Kutz from "../assets/KUTZU.png";
import Zou from "../assets/ZOU.png";

let gains = {
  poulette: [
    [1000, 0],
    [3000, 15],
    [5000, 30],
    [10000, 45],
    [20000, 60],
    [30000, 75],
    [50000, 90],
  ],
  ines: [
    [100000, 0],
    [300000, 1000],
    [500000, 1500],
    [1000000, 3000],
    [2000000, 5000],
    [3000000, 7000],
    [5000000, 10000],
  ],
  clavi: [
    [30000, 0],
    [60000, 150],
    [90000, 300],
    [120000, 500],
    [150000, 750],
    [300000, 900],
    [450000, 1000],
  ],
  thibaud: [
    [10000, 0],
    [40000, 20],
    [60000, 50],
    [130000, 70],
    [160000, 90],
    [200000, 120],
    [250000, 160],
  ],
  maka: [
    [500, 0],
    [1500, 10],
    [2000, 15],
    [5000, 20],
    [9000, 25],
    [20000, 30],
    [30000, 45],
  ],
  moop: [
    [50, 0],
    [100, 2],
    [200, 4],
    [300, 5],
    [400, 8],
    [800, 12],
    [1200, 14],
  ],
  fanta: [
    [200, 0],
    [350, 5],
    [450, 8],
    [600, 12],
    [900, 18],
    [1500, 25],
    [4000, 35],
  ],
  kutzu: [
    [50000000, 0],
    [300000000, 10000],
    [600000000, 50000],
    [1000000000, 150000],
    [2000000000, 300000],
    [10000000000, 500000],
    [100000000000000, 1000000],
  ],
  zou: [
    [600000000, 0],
    [4000000000, 300000],
    [7000000000, 1100000],
    [20000000000, 2800000],
    [40000000000, 8000000],
    [200000000000, 100000000],
    [3000000000000000, 200000000],
  ],
};

let store = window.localStorage;

export default function Cookie() {
  const [click, setClick] = useState(parseInt(store.getItem("clickk")) || 0);
  const [semestre, setSemestre] = useState(
    parseFloat(store.getItem("semestre")) || 1
  );

  const [score, setScore] = useState(parseInt(store.getItem("scoree")) || 0);
  const [maka, setMaka] = useState(parseInt(store.getItem("maka")) || 0);
  const [fanta, setFanta] = useState(parseInt(store.getItem("fanta")) || 0);
  const [kutzu, setKutzu] = useState(parseInt(store.getItem("kutzu")) || 0);
  const [zou, setZou] = useState(parseInt(store.getItem("zou")) || 0);

  const [moop, setMoop] = useState(parseInt(store.getItem("moop")) || 0);
  const [poulette, setPoulette] = useState(
    parseInt(store.getItem("poulette")) || 0
  );
  const [thibaud, setThibaud] = useState(
    parseInt(store.getItem("thibaud")) || 0
  );
  const [clavi, setClavi] = useState(parseInt(store.getItem("clavi")) || 0);
  const [ines, setInes] = useState(parseInt(store.getItem("ines")) || 0);

  useEffect(() => {
    const interval = setInterval(() => {
      callBack();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  let callBack = () => {
    let scoree = parseInt(store.getItem("scoree")) || 0;
    let moopp = parseInt(store.getItem("moop")) || 0;
    let zo = parseInt(store.getItem("zou")) || 0;

    let sem = parseFloat(store.getItem("semestre")) || 1;

    let kut = parseInt(store.getItem("kutzu")) || 0;
    let fantaa = parseInt(store.getItem("fanta")) || 0;
    let makaa = parseInt(store.getItem("maka")) || 0;
    let poule = parseInt(store.getItem("poulette")) || 0;
    let thib = parseInt(store.getItem("thibaud")) || 0;
    let clav = parseInt(store.getItem("clavi")) || 0;
    let vend = parseInt(store.getItem("ines")) || 0;

    setScore(
      Math.round(
        scoree +
          (gains.moop[moopp][1] +
            gains.fanta[fantaa][1] +
            gains.zou[zo][1] +
            gains.poulette[poule][1] +
            gains.thibaud[thib][1] +
            gains.clavi[clav][1] +
            gains.ines[vend][1] +
            gains.kutzu[kut][1] +
            gains.maka[makaa][1]) *
            sem
      )
    );
    store.setItem(
      "scoree",
      Math.round(
        scoree +
          (gains.moop[moopp][1] +
            gains.fanta[fantaa][1] +
            gains.zou[zo][1] +
            gains.poulette[poule][1] +
            gains.thibaud[thib][1] +
            gains.clavi[clav][1] +
            gains.ines[vend][1] +
            gains.kutzu[kut][1] +
            gains.maka[makaa][1]) *
            sem
      )
    );
  };

  let changerDeSemestre = () => {
    if (score >= Math.round(semestre * 1000000000000)) {

      store.setItem("semestre", semestre + 0.4);
      setSemestre(semestre + 0.4);

      setZou(0);
      setMaka(0);
      setScore(0);
      setKutzu(0);
      setFanta(0);
      setPoulette(0);
      setThibaud(0);
      setClavi(0);
      setInes(0);
      setClick(0);
      setMoop(0);
      store.setItem("scoree", 0);
      store.setItem("moop", 0);
      store.setItem("kutzu", 0);
      store.setItem("fanta", 0);
      store.setItem("maka", 0);
      store.setItem("zou", 0);
      store.setItem("poulette", 0);
      store.setItem("thibaud", 0);
      store.setItem("clavi", 0);
      store.setItem("ines", 0);
      store.setItem("clickk", 0);
    }
  };

  let calcClick = () => {
    let prix = 3;
    let valeur = 1;
    let valeur2 = 2;
    if (click < 40) {
      for (let i = 0; i < click; i++) {
        prix *= i > 10 ? 2 : 5;

        valeur *= i > 2 ? 2 : 1.6;
        valeur = Math.round(valeur);
        valeur2 *= i > 1 ? 2 : 1.6;
        valeur2 = Math.round(valeur2);
      }
    }
    return [prix, valeur, valeur2];
  };

  let upScore = (up) => {
    store.setItem("scoree", score + up);
    if (up) {
      setScore(score + up);
      return;
    }
  };

  let calc = calcClick();
  let name= semestre >= 4 ? "doctorat" : semestre >= 2.2 ? "master" : "semestre";
  return (
    <div className="theme">
      <h1 className="titre"> Bienvenue sur notre PipoClicker !! </h1>
      <h1 className="titre">
        {" "}
        Pour gagner de rares points assos, cliquez sur le Pipo{" "}
      </h1>
      <h1 className="titre" style={{ fontSize: 25 }}>
        {" "}
        Actuellement{" "}
        {new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
          Math.round(
            (gains.moop[moop][1] +
              gains.fanta[fanta][1] +
              gains.maka[maka][1] +
              gains.clavi[clavi][1] +
              gains.ines[ines][1] +
              gains.zou[zou][1] +
              gains.thibaud[thibaud][1] +
              gains.kutzu[kutzu][1] +
              gains.poulette[poulette][1]) *
              semestre
          )
        )}{" "}
        pts/s{" "}
        {" (multiplicateur de "+ name +" x" + semestre + ")"}
      </h1>
      <img src={Pipo} onClick={() => upScore(calc[1])} className="pipo"></img>
      <h1 className="titre">
        {" "}
        Vous possédez{" "}
        {new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
          score
        )}{" "}
        points assos {score >= 1000000 && "(Votre semestre est validé)"}
      </h1>

      <div
        className="embauche"
        onClick={() => {
          if (score >= calc[0]) {
            store.setItem("clickk", click + 1);
            setClick(click + 1);
            upScore(0 - calc[0]);
          }
        }}
      >
        <p>
          {" "}
          Vous êtes inscrits dans {new Intl.NumberFormat("de-DE", { style: "decimal" }).format(calc[1])} assos, voulez vous vous inscrire à
          {" " + new Intl.NumberFormat("de-DE", { style: "decimal" }).format((calc[2] - calc[1]))} nouvelles assos ?
          {" Prix : " +
            new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
              calc[0]
            )}
        </p>
      </div>

      <h1 className="shop">
        {" "}
        Embauchez nos agents afin de valider votre semestre :
      </h1>

      <div
        className="embauche"
        onClick={() => {
          if (score >= gains.moop[moop][0] && moop < 5) {
            store.setItem("moop", moop + 1);
            upScore(0 - gains.moop[moop][0]);
            setMoop(moop + 1);
          }
        }}
      >
        <img className="icone" src={Moop}></img>{" "}
        <p>
          {" "}
          Notre trésorier saura faire fructifier vos points (+
          {gains.moop[moop + 1][1]}/s){" "}
        </p>
        <p> Prix : {moop < 5 ? gains.moop[moop][0] + "s" : "Déjà au top"} </p>
      </div>
      <div
        className="embauche"
        onClick={() => {
          if (score >= gains.fanta[fanta][0] && fanta < 5) {
            store.setItem("fanta", fanta + 1);
            upScore(0 - gains.fanta[fanta][0]);
            setFanta(fanta + 1);
          }
        }}
      >
        <img className="icone" src={Fanta}></img>{" "}
        <p>
          {" "}
          Il est respo anim, il sait faire pression sur le Pipo (+
          {gains.fanta[fanta + 1][1]}/s){" "}
        </p>
        <p>
          {" "}
          Prix : {fanta < 5 ? gains.fanta[fanta][0] + "s" : "Déjà au top"}{" "}
        </p>
      </div>

      <div
        className="embauche"
        onClick={() => {
          if (score >= gains.maka[maka][0] && maka < 5) {
            store.setItem("maka", maka + 1);
            upScore(0 - gains.maka[maka][0]);
            setMaka(maka + 1);
          }
        }}
      >
        <img className="icone" src={Maka}></img>{" "}
        <p>
          {" "}
          Maka est prêt à tout pour vous, vraiment tout. (+
          {gains.maka[maka + 1][1]}/s){" "}
        </p>
        <p> Prix : {maka < 5 ? gains.maka[maka][0] + "s" : "Déjà au top"} </p>
      </div>

      <div
        className="embauche"
        onClick={() => {
          if (score >= gains.poulette[poulette][0] && poulette < 5) {
            store.setItem("poulette", poulette + 1);
            upScore(0 - gains.poulette[poulette][0]);
            setPoulette(poulette + 1);
          }
        }}
      >
        <img className="icone" src={Poulette}></img>{" "}
        <p>
          {" "}
          Poulette est respo com, elle saura faire valoir votre cas à l'admin.
          (+
          {gains.poulette[poulette + 1][1]}/s )
        </p>
        <p>
          {" "}
          Prix :{" "}
          {poulette < 5
            ? gains.poulette[poulette][0] + "s"
            : "Déjà au top"}{" "}
        </p>
      </div>

      <div
        className="embauche"
        onClick={() => {
          if (score >= gains.thibaud[thibaud][0] && thibaud < 5) {
            store.setItem("thibaud", thibaud + 1);
            upScore(0 - gains.thibaud[thibaud][0]);
            setThibaud(thibaud + 1);
          }
        }}
      >
        <img className="icone" src={Thibaud}></img>{" "}
        <p>
          {" "}
          Thibaud sait négocier, Thibaud est intelligent, soyez comme Thibaud
          (ou engagez le au moins) (+
          {gains.thibaud[thibaud + 1][1]}/s )
        </p>
        <p>
          {" "}
          Prix : {thibaud < 5
            ? new Intl.NumberFormat("de-DE", { style: "decimal" }).format(gains.thibaud[thibaud][0]) + "s"
            : "Déjà au top"}{" "}
        </p>
      </div>

      <div
        className="embauche"
        onClick={() => {
          if (score >= gains.clavi[clavi][0] && clavi < 5) {
            store.setItem("clavi", clavi + 1);
            upScore(0 - gains.clavi[clavi][0]);
            setClavi(clavi + 1);
          }
        }}
      >
        <img className="icone" src={Clavi}></img>{" "}
        <p>
          {" "}
          Ah... vous êtes si riche ? Notre vice présidente l'est encore plus,
          mais elle peut collaborer avec vous (+
          {gains.clavi[clavi + 1][1]}/s){" "}
        </p>
        <p>
          {" "}
          Prix : {clavi < 5 ? new Intl.NumberFormat("de-DE", { style: "decimal" }).format(gains.clavi[clavi][0]) + "s" : "Déjà au top"}{" "}
        </p>
      </div>

      <div
        className="embauche"
        onClick={() => {
          if (score >= gains.ines[ines][0] && ines < 5) {
            store.setItem("ines", ines + 1);
            upScore(0 - gains.ines[ines][0]);
            setInes(ines + 1);
          }
        }}
      >
        <img className="icone" src={Ness}></img>{" "}
        <p>
          {" "}
          Qui peut mieux vous aider que la patronne des associations ? (+
          {gains.ines[ines + 1][1]}/s){" "}
        </p>
        <p> Prix : {ines < 5 ? new Intl.NumberFormat("de-DE", { style: "decimal" }).format(gains.ines[ines][0]) + "s" : "Déjà au top"} </p>
      </div>

      <div
        className="embauche"
        onClick={() => {
          if (score >= gains.kutzu[kutzu][0] && kutzu < 5) {
            store.setItem("kutzu", kutzu + 1);
            upScore(0 - gains.kutzu[kutzu][0]);
            setKutzu(kutzu + 1);
          }
        }}
      >
        <img className="icone" src={Kutz}></img>{" "}
        <p>
          {" "}
          Kutzu déborde d'énergie, mais elle déborde surtout de points assos,
          elle peut vous en donner quelques-uns ? (+
          {new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
            gains.kutzu[kutzu + 1][1]
          )}
          /s){" "}
        </p>
        <p>
          {" "}
          Prix :{" "}
          {kutzu < 5
            ? new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                gains.kutzu[kutzu][0]
              ) + "s"
            : "Déjà au top"}{" "}
        </p>
      </div>

      <div
        className="embauche"
        onClick={() => {
          if (score >= gains.zou[zou][0] && zou < 5) {
            store.setItem("zou", zou + 1);
            upScore(0 - gains.zou[zou][0]);
            setZou(zou + 1);
          }
        }}
      >
        <img className="icone" src={Zou}></img>{" "}
        <p>
          {" "}
          En temps que PDG d'Evry, Zou a le bras très long. Il saura parler aux bonnes personnes (+
          {new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
            gains.zou[zou + 1][1]
          )}
          /s){" "}
        </p>
        <p>
          {" "}
          Prix :{" "}
          {zou < 5
            ? new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                gains.zou[zou][0]
              ) + "s"
            : "Déjà au top"}{" "}
        </p>
      </div>

      <h2 className="sem" onClick={changerDeSemestre}>
        {" "}
        Changer de {name} ? Prix :{" "}
        {new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
          Math.round(semestre * 1000000000000)
        )}{" "}
      </h2>
    </div>
  );
}
