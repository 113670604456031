import React,{useEffect, useState} from "react";
import { ReactNavbar } from "react-responsive-animate-navbar";
import "./NavBar.css";
import { Link } from "react-router-dom";
import Switch from "react-switch";

window.addEventListener("scroll", function () {
  var header = document.querySelector("header");
  header.classList.toggle("sticky", window.scrollY > 0);
});

let fr = ["Accueil","L'équipe","Programme","Activités","Partenariats"];
let en = ["Home","Our team","Program","Activities","Partnerships"];

export default function NavBar() {

  const [checked,check] = useState(document.location.pathname.substring(document.location.pathname.length - 2,document.location.pathname.length).indexOf("en") == -1);

  let tab = en;
  let lang = "en";
  let contrary = "fr";
  if(checked) {
    tab = fr;
    lang="fr";
    contrary = "en";

  }

  return (
    <div>
      <header>
        <Link className="logo" to={"/"+lang}>
          {" "}
          CLASSIFIED{" "}
        </Link>

        
        <div className="navbar-links">
          <ul>
            <li>
              {" "}
              <Link to={"/"+lang}> {tab[0]} </Link>
            </li>
            <li>
              {" "}
              <Link to={"/equipe/"+lang}> {tab[1]} </Link>
            </li>
            <li>
              {" "}
              <Link to={"/programme/"+lang}> {tab[2]} </Link>
            </li>
            <li>
              {" "}
              <Link to={"/activites/"+lang}> {tab[3]} </Link>
            </li>
            <li>
              {" "}
              <Link to={"/partenariats/"+lang}> {tab[4]} </Link>
            </li>
          </ul>
        </div>
        <div className="div_switch">
        <Switch
          checked={checked}
          onChange={(e) => {
            check(!checked);
            window.location.href = document.location.origin + document.location.pathname.substring(0,document.location.pathname.length - 2) + contrary;
          }}
          offColor="#b8a57a"
          onColor="#224235"
          offHandleColor="#224235"
          onHandleColor="#b8a57a"
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "black",
                paddingRight:2,
              }}
            >
              EN  
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "#b8a57a",
              }}
            >
              FR
            </div>
          }
          className="react-switch"
          id="icon-switch"
        />
        <a
          href="#"
          className="toggle-button"
          onClick={() => {
            let navbarLinks = document.getElementsByClassName(
              "navbar-links"
            )[0];
            let go = document.querySelector("header");
            go.classList.toggle("active");
            navbarLinks.classList.toggle("active");
          }}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </a>
        </div>
      </header>
      <section className="banner"> </section>
    </div>
  );
}
