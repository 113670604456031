import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import LoopIcon from "@material-ui/icons/Search";
import VoteIcon from "@material-ui/icons/HowToVote";
import People from "@material-ui/icons/EmojiPeople";
import Party from "@material-ui/icons/PartyMode";
import Tv from "@material-ui/icons/Tv";
import Prog from "../assets/Programme.png";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "./Equipe.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 13px",
    width: "100%",
    maxWidth: "1000px",
    color: "rgb(184, 165, 122)",
    textAlign: "center",
    background: " rgb(42, 42, 41)",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  paper2: {
    direction: "rtl",
  },
}));

const activitesFR = [
  {
    heure: "26 Novembre - 10 Décembre",
    title: "Le fil rouge",
    description:
      "On vous a préparé des activités/jeux incroyables, le but étant de s'amuser mais aussi de gagner (bien sûr). Soyez le(la) meilleur(e), montez dans le classement et tentez de décrocher le gros lot (l'année dernière ils ont gagné une PS4 par exemple...).",
    icon: <People />,
  },
  {
    heure: "27 Novembre",
    title: "Coup de foudre à l’assemblée ! (APN)",
    description:
      "Pataquès au Parlement galactique ! Alors que l’univers subit une crise grave, liée à la Covid-56 et à l’explosion du prix du voyage en hypervitesse, les députés ne trouvent rien de mieux à faire que de s’envoyer en l’air ! Nous avons besoin de vous pour résoudre le mystère de ces folies passagères et démasquer ces couples frivoles qui mettent en péril l’équilibre de la Force !",
    icon: <Party />,
  },
  {
    heure: "28/29 Novembre",
    title: "WEEK END STREAM",
    description:
      "39h. Non stop. Au programme : jeux, discussions, annonces... vous n'êtes pas prêts ! Nous non plus.  Fin de la transmission.",
    icon: <Tv />,
  },
  {
    heure: "02 Décembre",
    title: "Alchimie X Classified (APN)",
    description:
      "Alors que le soleil se couche paisiblement sur nos têtes, les rues de nos villes sont vides de bruit et d’âme. A quelques mètres de la surface cependant, des sons et vibrations se font ressentir : la fête bat son plein dans notre cabaret secret. Lumière et musique palpitent au rythme de nos sens exacerbés par ces concoctions circulant dans la salle, dont la formule est bien gardée par les derniers héritiers de Flamel.",
    icon: <Party />,
  },
  {
    heure: "05/06 Décembre",
    title: "WEEK END STREAM",
    description:
      "39h. Non stop. Encore une fois ? Au programme : jeux, discussions, annonces... vous n'êtes pas prêts ! Nous non plus.  Fin de la transmission.",
    icon: <Tv />,
  },
  {
    heure: "07 Décembre",
    title: "Unchaiined X Classified (APN)",
    description:
      "Les agents de CLASSIFIED ont été pris à leur propre jeu ! Après avoir arrêté et fait emprisonner le réseau de contrebandiers UNCHAiiNeD, ces derniers se sont évadés et ont décidé de se venger. Un de nos membres se retrouve maintenant séquestré dans un ancien asile désaffecté et a besoin de vous pour le libérer de ses chaînes.",
    icon: <Party />,
  },
  {
    heure: "Fin : ######",
    title: "La grande enquête",
    description:
      "Réussirez-vous à trouver l'infiltré ? Il aurait, paraît-il, laissé s'échapper des indices. Saurez-vous les retrouver ?",
    icon: <LoopIcon />,
  },
  {
    heure: "10 Décembre",
    title: "VOTEZ",
    description:
      "Après nous avoir suivi dans cette campagne pendant 2 semaines et avoir pris un maximum de plaisir, viendra l'heure du vote ! Alors VOTEZ, j'ai besoin de le répéter ? (votez)",
    icon: <VoteIcon />,
  },
];

const activitesEN = [
  {
    heure: "26 November - 10 December",
    title: "The red thread",
    description:
      "We have prepared some incredible activities/games for you, the goal being to have fun but also to win (of course). Be the best, climb the leaderboard and try to win the jackpot (last year they won a PS4 for example...).",
    icon: <People />,
  },
  {
    heure: "27 November",
    title: "Coup de foudre à l’assemblée ! (APN)",
    description:
      "Battles at the Galactic Parliament! While the universe is undergoing a serious crisis, linked to the Covid-56 and the explosion of the price of hyperspeed travel, the MPs can't find anything better to do than to get laid! We need your help to solve the mystery of these temporary follies and unmask these frivolous couples who endanger the balance of the Force!",
    icon: <Party />,
  },
  {
    heure: "28/29 November",
    title: "STREAMING WEEK END",
    description:
      "39h. Non stop. On the program: games, discussions, announcements... you're not ready! Neither are we. End of transmission.",
    icon: <Tv />,
  },
  {
    heure: "End : ######",
    title: "The great investigation",
    description:
      "Will you succeed in finding the infiltrator? It seems that he has let some clues escape. Will you be able to find them?",
    icon: <LoopIcon />,
  },
  {
    heure: "10 December",
    title: "VOTE",
    description:
      "After having followed us in this campaign for 2 weeks and having had a lot of fun, it's time to vote! So VOTE, do I need to repeat it? (vote)",
    icon: <VoteIcon />,
  },
];

export default function Programme(props) {
  const classes = useStyles();
  var lang = props.match.params.lang;
  const activites = lang == "fr" ? activitesFR : activitesEN;

  return (
    <div className="equipe">
      <h1> {lang == "fr" ? "Programme de nos activités :" : "Schedule :"} </h1>

      <Timeline align="alternate">
        {activites.map((e, i) => (
          <TimelineItem key={e.title}>
            <TimelineOppositeContent>
              <Typography
                variant="body2"
                style={{ color: "white", fontWeight: "bold", paddingTop: 10 }}
              >
                {e.heure}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                style={{
                  color: "rgb(184, 165, 122)",
                  background: "rgb(42, 42, 41)",
                }}
              >
                {e.icon}
              </TimelineDot>
              {i < activites.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent className={i % 2 != 0 ? classes.paper2 : ""}>
              <Paper elevation={3} className={classes.paper + " item item" + i}>
                <Typography variant="h6">{e.title}</Typography>
                <Typography> {e.description}</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <h1>
        {" "}
        {lang == "fr"
          ? "Planning des streams :"
          : "Streaming schedule :"}{" "}
      </h1>
      <img
        style={{ maxWidth: 800, width: "90%", marginBottom: 80 }}
        src="https://lh3.googleusercontent.com/proxy/gmRIgmRePMilsBJ46qBT8pXMGGw627ZhlO-srdHLnSDT28SJ_ui9k42DZJaSbWB9dEZHk8LySe3Jj7Yya9NuD7SvABcM1yWSJcN10RYxc8xWqNx1LTSfBO9UlgUzbURLzvJzs34OVvwxHlm8-0lcec9zHWbqYHj9ygg"
        alt="comingSoon"
      />
    </div>
  );
}
